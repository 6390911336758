import axios from "axios";

export default axios.create({
  // ezdan link
  // baseURL: "https://arresturanttestapi.smartvillageqatar.com/",

  // restui link
  // baseURL: "https://restaurantapitest.smartvillageqatar.com/",

  // production link
  baseURL: "https://mrbuffaloapi.smartvillageqatar.com/",
});
