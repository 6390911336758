import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  // useContext,
} from "react";
import waiterfillAxios from "../../api/waiterfillAxios";
import "../../assets/styles/takeaway.css";
import Swal from "sweetalert2";
import axiosAuthInstance from "../../api/axiosAuthInstance";
import { Spinner } from "../ui/Spinner";
import { Link } from "react-router-dom";
// import { UserSettingsContext } from "../../context/UserSettingsContext";

export const WaiterFill = ({
  toggleWaiterFill,
  employee,
  updateWaiterFillOrdersCount,
  waiterfillOrdersCount,
  dineinOrdersCount,
  updateDineinOrdersCount,
  loadNewPrintMethod,
}) => {
  const [waiterfillOrders, setWaiterfillOrders] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeApprovedId, setActiveApprovedId] = useState(null);
  const [activeCloseId, setActiveCloseId] = useState(null);
  const [disableApproveButton, setDisableApproveButton] = useState(false);
  const [disableCloseButton, setDisableCloseButton] = useState(false);
  const approveButtonsRef = useRef();
  const closeButtonsRef = useRef();
  // const userSettingsContext = useContext(UserSettingsContext);
  useEffect(() => {
    const fetchDineinOrders = async () => {
      // ezdan link
      // const response = await waiterfillAxios(`main_orders?shopId=suYykEsRB`);

      // production link
      const response = await waiterfillAxios(`main_orders?shopId=WaqL2LPTI`);
      setWaiterfillOrders(response.data.orders);
    };
    try {
      fetchDineinOrders();
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: `Something went wrong while fetching fine dine orders`,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  }, []);

  const toggleShowDinein = () => {
    document.querySelector(".dinein-container").classList.toggle("show");
    setTimeout(() => {
      toggleWaiterFill();
    }, 300);
  };
  useEffect(() => {
    setTimeout(() => {
      document.querySelector(".dinein-container").classList.add("show");
    }, 0);
  }, []);

  const printer = (fileUrl) => {
    const newWin = window.open(fileUrl, "printing", "width=11,height=11");

    setTimeout(() => {
      newWin.close();
    }, 5000);
  };

  const closeOrder = async (order) => {
    setDisableCloseButton(!disableApproveButton);
    setIsLoading(!isLoading);

    setActiveCloseId(order.PLR_ID);
    await waiterfillAxios.post("/close_main_order", {
      orderId: order.PLR_ID,
    });

    let modifiedOrders = waiterfillOrders.filter((item) => {
      return item.PLR_ID !== activeCloseId;
    });

    setWaiterfillOrders(modifiedOrders);

    setDisableCloseButton(false);
    setIsLoading(false);
    updateWaiterFillOrdersCount(waiterfillOrdersCount - 1);
  };

  const approveOrder = async (order) => {
    setDisableApproveButton(!disableApproveButton);
    setIsLoading(!isLoading);

    try {
      setActiveApprovedId(order.PLR_FD_READABLE_ID);
      const archiveResponse = await waiterfillAxios.post(
        "/archive_main_order",
        {
          orderId: order.PLR_ID,
        }
      );

      const { archivedOrder } = archiveResponse.data;

      // creating dine in order from the fine din archived main order.
      const dineinOrder = {};
      dineinOrder.totalPrice = archivedOrder.PLR_FD_TOTAL_TOTAL;
      dineinOrder.fkDeliveryBoyId = null;
      dineinOrder.fkOrderTypeId = 1;
      dineinOrder.fkWaiterId = employee.pkEmpId;
      dineinOrder.fkShiftD = parseInt(sessionStorage.getItem("shiftID"));
      dineinOrder.fkSafeId = employee.fkSafeId;
      dineinOrder.discount = archivedOrder.PLR_FD_TOTAL_DISCOUNTS;
      dineinOrder.tableId = order.table.PK_Table_ID;
      dineinOrder.close = false;
      dineinOrder.done = false;
      dineinOrder.cashValue = 0;
      dineinOrder.visaValue = 0;
      dineinOrder.payed = 0;
      dineinOrder.isCash = false;
      dineinOrder.isVisa = false;
      dineinOrder.isMix = false;
      dineinOrder.fkClientId = null;
      dineinOrder.fk_Company_ID = null;
      dineinOrder.compOrderValue = 0;
      dineinOrder.date = new Date();
      dineinOrder.fkBranchId = employee.fkBranchId;
      dineinOrder.orderAdress = null;
      dineinOrder.orderTel = null;
      dineinOrder.onHold = null;
      dineinOrder.carNo = null;
      dineinOrder.carType = null;
      dineinOrder.fkColorId = null;
      dineinOrder.carColor = null;
      dineinOrder.delivered = null;
      dineinOrder.readyToPay = null;
      dineinOrder.ishost = null;
      dineinOrder.hosting = null;
      dineinOrder.isCombined = null;
      dineinOrder.fkOrderMasterIdcombinedFrom = null;
      dineinOrder.discountReason = null;

      // creating objects for order details
      let orderDetails = [];
      order.order_details.forEach((item) => {
        let orderDetailsItem = {};

        orderDetailsItem.returnMethod = null;
        orderDetailsItem.cash_safe_id = null;
        orderDetailsItem.visaSafe_id = null;
        orderDetailsItem.void = false;
        orderDetailsItem.fkItemId = item.sv_item_id;
        orderDetailsItem.qty = item.PLR_FD_QUANTITY;
        orderDetailsItem.discount = 0;
        orderDetailsItem.notes = item.PLR_FD_NOTES;
        orderDetailsItem.done = false;
        orderDetailsItem.return = false;
        orderDetailsItem.price = item.PLR_FD_TOTAL_PRICE;
        orderDetailsItem.itemClose = false;
        orderDetailsItem.cost = 0;
        orderDetailsItem.detailsIndex = 0;
        orderDetailsItem.saledPrice = 0;

        orderDetails.push(orderDetailsItem);
      });

      const saveUpdateOrderResponse = await axiosAuthInstance.post(
        `cashier/saveUpdateOrder`,
        {
          orderMaster: dineinOrder,
          orderDetails: orderDetails,
        }
      );

      const { pkOrderMasterID } = saveUpdateOrderResponse.data.rs;
      const shiftId = JSON.parse(sessionStorage.getItem("shiftID"));
      const settings = JSON.parse(localStorage.getItem("settings"));
      if (settings && settings.newPrintMethod) {
        loadNewPrintMethod(pkOrderMasterID);
      } else {
        // production link
        printer(`http://mrbuffalosoldprinting.smartvillageqatar.com/cashier/invoice.aspx?reportKey=${pkOrderMasterID}&shiftid=${shiftId}&userid=${employee.pkEmpId}&branchid=${employee.fkBranchId}&LangeSymbol=EN&PrintDinin=Print&PrintOnly=False
          `);
        // ezdan link
        // printer(`http://newbranchtestoldprint.smartvillageqatar.com/cashier/invoice.aspx?reportKey=${pkOrderMasterID}&shiftid=${shiftId}&userid=${employee.pkEmpId}&branchid=${employee.fkBranchId}&LangeSymbol=EN&PrintDinin=Print&PrintOnly=False
        //   `);
      }

      if (saveUpdateOrderResponse.data.rs.errMsg) {
        Swal.fire({
          title: "Error!",
          text: `${saveUpdateOrderResponse.data.rs.errMsg}. Please Login again to open shift`,
          icon: "error",
          confirmButtonText: "Ok",
        });
        setIsLoading(false);

        return;
      }

      let orderTableObj = {
        fK_Table_ID: dineinOrder.tableId,
        masterOrderID: pkOrderMasterID,
        exist: true,
      };

      await axiosAuthInstance.post(
        `Cashier/SaveUpdateOrderTable`,
        orderTableObj
      );

      await waiterfillAxios.post("/update_main_order", {
        orderId: pkOrderMasterID,
        finedineOrderId: archivedOrder.PLR_ID,
      });

      let modifiedOrders = waiterfillOrders.filter((item) => {
        return item.PLR_ID !== archivedOrder.PLR_ID;
      });

      setWaiterfillOrders(modifiedOrders);

      Swal.fire({
        title: "Success!",
        text: `Order archived successfully`,
        icon: "success",
        confirmButtonText: "Ok",
      });

      setDisableApproveButton(false);
      setIsLoading(false);
      updateWaiterFillOrdersCount(waiterfillOrdersCount - 1);
      updateDineinOrdersCount(dineinOrdersCount + 1);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setDisableApproveButton(false);

      Swal.fire({
        title: "Error!",
        text: `Something went wrong`,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  const renderOrderItems = (orderItems) => {
    return (
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Item Name</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {orderItems ? (
            orderItems?.map((item, index) => (
              <tr key={index}>
                <td>{item["ItemName_Lang2"] ?? item["name"]}</td>
                <td>{item["PLR_FD_QUANTITY"]}</td>
              </tr>
            ))
          ) : (
            <tr></tr>
          )}
        </tbody>
      </table>
    );
  };

  const renderOrder = (item) => {
    return (
      <span key={item.PLR_FD_READABLE_ID}>
        {item.order_details?.map((item) => {
          return `${item.ItemName_Lang2 ?? item.name} (${
            item.PLR_FD_QUANTITY
          }),`;
        })}
      </span>
    );
  };

  return (
    <div style={{}}>
      <div
        className="ta-wrapper"
        onClick={() => {
          toggleShowDinein();
        }}
      ></div>
      <div className={`dinein-container`} onClick={(e) => e.stopPropagation()}>
        <table className="ta-table dinein-orders-table">
          <thead>
            <tr>
              <th className="">Order-No</th>
              <th className="">Date Time</th>
              <th className="">Table</th>
              <th className="">Waiter</th>
              <th className="ta-ordertitle">Order</th>
              <th className="">Action</th>
            </tr>
          </thead>
          <tbody>
            {waiterfillOrders ? (
              waiterfillOrders.map((item) => (
                <tr key={item.PLR_FD_READABLE_ID}>
                  <td className="">{item.PLR_FD_READABLE_ID}</td>
                  <td className="">
                    {new Date(item.FD_ORDER_CREATED_AT).toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      }
                    )}
                  </td>
                  <td className="">{item.PLR_FD_TABLE_NAME}</td>
                  <td className="">{item.PLR_FD_EMPLOYEE}</td>
                  <td className="ta-itemname">
                    <p>{renderOrder(item)}</p>
                    <div className="order-tooltip">
                      {renderOrderItems(item.order_details)}
                    </div>
                  </td>
                  <td className="">
                    <Fragment>
                      {isLoading &&
                      item.PLR_FD_READABLE_ID === activeApprovedId ? (
                        <Spinner />
                      ) : item.table == null ||
                        item.order_details.length === 0 ? (
                        <button className="disabled-btn">Disabled</button>
                      ) : item.nullItem ? (
                        <>
                          <Link
                            className="mapping-link"
                            to="/map-products"
                            state={item}
                          >
                            Match
                          </Link>
                          <button
                            style={{ marginLeft: "0.4rem" }}
                            disabled={disableCloseButton}
                            ref={closeButtonsRef}
                            onClick={() => {
                              closeOrder(item);
                            }}
                          >
                            Reject
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            disabled={disableApproveButton}
                            ref={approveButtonsRef}
                            className="approve-btn"
                            onClick={() => {
                              approveOrder(item);
                            }}
                          >
                            Approve
                          </button>
                          <button
                            style={{ marginLeft: "0.4rem" }}
                            disabled={disableCloseButton}
                            ref={closeButtonsRef}
                            onClick={() => {
                              closeOrder(item);
                            }}
                          >
                            Reject
                          </button>
                        </>
                      )}
                    </Fragment>
                  </td>
                </tr>
              ))
            ) : (
              <tr></tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
